import { render, staticRenderFns } from "./CombinationTab.vue?vue&type=template&id=688c8544&scoped=true"
import script from "./CombinationTab.vue?vue&type=script&lang=js"
export * from "./CombinationTab.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "688c8544",
  null
  
)

export default component.exports