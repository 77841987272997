<template>
  <div>
    <WeekSwiper :combinationTabIndex="combinationTabIndex" />
    <b-alert
      v-if="isLoading && !renderListLength"
      show
      variant="info"
      class="mb-0 p-1"
    >
      {{ $t('flight.searching') }}...
    </b-alert>
    <b-alert
      v-else-if="!isLoading && !renderListLength"
      show
      variant="warning"
      class="mb-0 p-1"
    >
      {{ $t('flight.notBookingFound') }}
    </b-alert>
    <div class="mt-1">
      <div
        v-infinite-scroll="loadMore"
        infinite-scroll-distance="800"
      >
        <div
          v-for="(tripItem) of infiniteItems"
          :key="tripItem.id"
        >
          <BCard
            no-body
            class="px-1 py-50"
            :style="`${selected && selected.id === tripItem.id ? 'border: 2px solid #efad02;' : 'border: 2px solid transparent;'}`"
          >
            <DetailPriceCollapse
              :key="`DetailPriceCollapse-${tripItem.id}`"
              :trip-item="tripItem"
              :is-checked="selected?.id === tripItem.id"
            />
            <Trips
              :key="`Trips-${tripItem.id}`"
              :trip-item="tripItem"
              :selected-trip="selected"
            />
          </BCard>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BCard, BAlert,
} from 'bootstrap-vue'
import {
  computed, ref, watch, toRefs,
} from '@vue/composition-api'

export default {
  components: {
    BCard,
    BAlert,
    DetailPriceCollapse: () => import('./DetailPriceCollapse.vue'),
    Trips: () => import('./Trips.vue'),
    WeekSwiper: () => import('./WeekSwiper.vue'),
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: [Object, null],
      default: null,
    },
    renderList: {
      type: Array,
      default: () => [],
    },
    combinationTabIndex: {
      type: [Number, null],
      default: null,
    },
  },
  setup(props) {
    const renderListProps = toRefs(props).renderList
    const renderListLength = computed(() => renderListProps.value.length)

    // INFINITE SCROLL
    const infiniteItems = ref([])
    const infinitePage = ref(0)

    watch(
      renderListProps,
      renderListProps => {
        infiniteItems.value = renderListProps.slice(0, 10)
        infinitePage.value = 1
      },
    )

    const loadMore = () => {
      if (infiniteItems.value.length >= renderListProps.value.length) return
      const nextItems = renderListProps.value.slice(infinitePage.value * 10, (infinitePage.value + 1) * 10)
      infiniteItems.value = infiniteItems.value.concat(nextItems)
      infinitePage.value += 1
    }

    return {
      renderListProps,
      renderListLength,
      infiniteItems,
      loadMore,
    }
  },
}
</script>
<style scoped></style>
